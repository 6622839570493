@font-face {
  font-family: "Isidora-Medium";
  src: url('fonts/IsidoraMedium/font.woff2') format('woff2'), url('fonts/IsidoraMedium/font.woff') format('woff');
}

body {
  font-family: 'Isidora-Medium';
  font-size: 1.1rem;
  color: #333;
  text-shadow: 0rem 0rem .04rem #555;
}

.bg-semi-transparent {
  background: rgba(255, 255, 255, 0.97);
}

a,
.brand-color {
  color: #006892;
}

li {
  font-size: 1rem;
}
